<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div>
      <div class="col s12">
        <div class="card">
          <div class="card-content">
            <div class="row no-margin">
              <div id="viewAll" class="col s12">
                <div class="row no-margin">
                  <div class="col s12">
                    <Table
                      :offLineMode="true"
                      :fields="tableComponentData.fields"
                      :loadOfflineEntries="loadOfflineEntries"
                      :perPageOptions="tableComponentData.perPageOptions"
                      tableTitle="Monitor"
                      actions="true"
                      :actionList="tableComponentData.actionList"
                      :defaultSearchColumns="['name']"
                    >
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css");
nav {
  background-color: white !important;
}
</style>
<script>
import Table from "data-table-vue"; // import data-table-vue
export default {
  components: { Table }, // declare it as component
  data() {
    return {
      tableComponentData: {
        perPageOptions: [10, 100],
        fields: [
          {
            name: "ID",
            key: "id"
          },
          {
            name: "Artist Name",
            key: "name"
          },
          {
            name: "Total Event",
            key: "total_event"
          },
          {
            name: "tmr",
            key: "tmr"
          },
          {
            name: "ticketweb",
            key: "ticketweb"
          },
          {
            name: "mfx_nl",
            key: "mfx_nl"
          },
          {
            name: "ticket_master",
            key: "ticket_master"
          }
          //  {
          //    name: "filtered",
          //    key: "filtered"
          //  }
        ]
      }
    };
  },
  methods: {
    getData() {
      let randomData = [];

      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch("https://varilytics.com/api/monitor", requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result);
          result = result.data;

          for (let i = 0; i < result.length; i++) {
            randomData.push({
              id: i + 1,
              name: `${result[i].artist_name}`,
              total_event: `${result[i].total_event}`,
              tmr: `${result[i].tmr}`,
              ticketweb: `${result[i].ticketweb}`,
              mfx_nl: `${result[i].mfx_nl}`,
              ticket_master: `${result[i].ticket_master}`,
              filtered: `${result[i].filtered}`
            });
          }
        })
        .catch(error => console.log("error", error));

      return randomData;
    },
    loadOfflineEntries(cb) {
      let data = {
        status: 200,
        msg: this.getData()
      };
      cb(data);
    }
  }
};
</script>
